import React, {useState, useRef} from 'react';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import {CSVLink} from 'react-csv';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es'; // the locale you want
import Loader from '../../Loader';

registerLocale('es', es);

const OrdersTeleventa = (props) => {
    const [loading, setLoading] = useState(false);
    const [dateFrom, setDateFrom] = useState(new Date().setDate(new Date().getDate() - 30));
    const [dateTo, setDateTo] = useState(new Date().setDate(new Date().getDate() + 1));
    const [transactionData, setTransactionData] = useState([]);
    const csvLink = useRef();

    function submitHandler(event) {
        event.preventDefault()
        setLoading(true)
        axios({
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id')
            },
            url: `${localStorage.getItem('url')}/reportes/ordersteleventa?dateFrom=${dateFrom.toISOString().slice(0, 10)}&dateTo=${dateTo.toISOString().slice(0, 10)}`,
        }).then(function (response) {
            if (response.status === 200) {
                if (response.data.length > 0) {
                    mapDataToCsv(response.data)
                } else {
                    alert("No hay datos para descargar")
                }
            }
            setLoading(false)
        }).catch(e => {
            if (e.response.status === 401 || e.response.status === 403) {
                window.location.href = `${window.location.origin}/login`
            }
        });
    }

    function mapDataToCsv(data) {
        setTransactionData([])
        data.map((item, i) => {
            setTransactionData((prevTransactionData) => [
                ...prevTransactionData,
                {
                    Fecha: item.fecha,
                    Tipo: item.type,
                    "Código de vendedor": item.codigo_vendedor,
                    "Número de carrito": item.quote_id,
                    "Contenido de pedido": item.items,
                    "Channel Id": item.channel_id,
                    "Email de usuario": item.user_email,
                },
            ]);
        });
        setTimeout(function () {
            csvLink.current.link.click();
        }, 1000);
    }

    return (
        <div className="main-container" id="main">
            <div className="title  ml-4">Pedidos de televenta</div>
            <p className="form-label text-nowrap pt-1 pr-1 text-muted ml-4">
                Descargar un listado de fecha, cantidad de carritos compartidos en televenta
            </p>
            <div className="actions-container">
                <div className="search-container">
                    <form onSubmit={submitHandler}>
                        <div className="actions">&nbsp;</div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label className=" control-label">Desde:</label>
                                <DatePicker
                                    locale="es"
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control ml-1"
                                    selected={dateFrom}
                                    onChange={date => setDateFrom(date)}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label className=" control-label">Hasta:</label>
                                <DatePicker
                                    locale="es"
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control ml-1"
                                    selected={dateTo}
                                    onChange={date => setDateTo(date)}
                                />
                            </div>
                        </div>
                        <button type="submit" className="blue wider font-18">
                            <FontAwesomeIcon
                                className="text-white mr-1"
                                icon={faDownload}
                            />
                            Descargar
                        </button>
                    </form>
                </div>
            </div>

            {loading && (<Loader/>)}

            <CSVLink
                data={transactionData}
                separator={";"}
                filename={"Carritos televenta.csv"}
                target="_blank"
                ref={csvLink}
            />
        </div>
    );
}

export default OrdersTeleventa;
