import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import moment from 'moment';
import jsPdf from 'jspdf';

import Context from '../../../context';
import Loader from '../../Loader';
import CheckBox from './checkbox';
import CustomModal from '../../Modals/CustModal';
import CommentModal from '../../Modals/CommentModal';
import StatusModal from '../../Modals/StatusModal';
import UnassignmentReasonsModal from '../../Modals/UnassignmentReasons';
import LocationOrderModal from '../../Modals/LocationOrderModal';
import OrderPrintingOptions from './OrderPrintingOptions';
import SkuContainer from './skucontainer';
import OrderStatus from "./OrderStatus";

import {getOmsUrl} from '../../../tools/utils/omsUrl';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClipboard, faCopy, faShoppingBag,} from '@fortawesome/free-solid-svg-icons';
import {
    APPROVED,
    ASSIGNED,
    CUSTOMER,
    CUSTOMER_CARE,
    DELIVERED,
    DISPATCHED,
    EXTERNAL_RMA_REQUEST,
    PREPARED,
    READY_FOR_PICKUP,
    REFUNDED,
    RMA_REQUESTED,
    SYSTEM
} from "../../../tools/utils/omsStateUtils";

export const printTicket = (order) => {
    const pdf = new jsPdf({
        orientation: 'p',
        unit: 'cm',
        format: [6, 10],
    });

    pdf.setFontSize(9);
    pdf.text(`Nombre: ${order.customer_name}`, 1, 1);
    pdf.text(`Apellido: ${order.customer_lastname}`, 1, 2);
    pdf.text(`DNI: ${order.customer_document}`, 1, 3);
    pdf.text(`Nro. pedido: ${order.increment_id}`, 1, 4);
    pdf.text('Fecha creación: ', 1, 5);
    pdf.text(
        moment(order.created_at).add('-3', 'hours').format('DD/MM/YYYY, HH:mm:ss'),
        1,
        6,
    );

    pdf.save(`${new Date().toISOString()}.pdf`);
};

const OrderTableRow = React.forwardRef((props, ref) => {
    const {
        clearToStartRow,
        showModal,
        isChecked,
        configData,
        unassignmentReasons,
        channels,
    } = useContext(Context);
    const { reloadGrid } = props;

    const [imprimir, setImprimir] = useState(false);
    const [facturar, setFacturar] = useState(false);
    const [textStatus, setTextStatus] = useState('');
    const [actionState, setActionState] = useState('');
    const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
    const [isOpenCommentModal, setIsOpenCommentModal] = useState(false);
    const [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
    const [isOpenUnassignmentReasons, setIsOpenUnassignmentReasons] =
        useState(false);
    const [isOpenLocationModal, setIsOpenLocationModal] = useState(false);
    const [locationOrderSend, setLocationOrderSend] = useState(false);
    const [isOrderNumberCopied, setIsOrderNumberCopied] = useState(false);
    const [unassignmentReasonId, setUnassignmentReasonId] = useState(null);
    const [unassignmentReasonDescription, setUnassignmentReasonDescription] =
        useState(null);
    const [cardClass, setCardClass] = useState('card-container ');
    const [actionsButtons, setActionsButtons] = useState([]);

    const order = props.order;
    const skus = order.skus;

    const [isPickup, setIsPickup] = useState(
        order.shipping_method.toLowerCase().includes('pickuppoint') ||
        order.shipping_method.toLowerCase().includes('brandpu') ||
        order.shipping_method.toLowerCase().endsWith('n_t'),
    );

    const numberPattern = /\d+/g;

    const getActionButton = (state, label, className, action) => {
        if (!action) {
            return <></>;
        }

        const handleClick = () => {
            if (state) {
                action(state);
            } else {
                action();
            }
        };

        return (
            <button key={state} className={className} onClick={handleClick}>
                {label}
            </button>
        );
    };

    const getCancelLabelStatus = (order) => {
        let requestedBy = '';
        switch (order.rma_requested_by_user_type) {
            case CUSTOMER:
            case CUSTOMER_CARE:
                requestedBy = 'por el cliente';
                break;
            case SYSTEM:
                requestedBy = 'por plazo de entrega excedido'
                break;
        }
        return requestedBy;
    }

    const getRmaRequestLabelStatus = (order) => {
        let requestedBy = 'Cancelado';
        switch (order.rma_requested_by_user_type) {
            case CUSTOMER:
            case CUSTOMER_CARE:
                requestedBy = 'Cancelado por el cliente';
                break;
            case SYSTEM:
                requestedBy = 'Plazo de entrega excedido'
                break;
        }
        return requestedBy;
    }

    useEffect(() => {
        if (actionState === READY_FOR_PICKUP && locationOrderSend) {
            actionByState();
        }
    }, [locationOrderSend]);

    useEffect(() => {
        setActionsButtons([]);
        switch (order.order_status) {
            case ASSIGNED:
                order.usa_factura_glamit ? setFacturar(false) : setFacturar(true);
                setImprimir(false);

                const prepareAction = getActionButton(READY_FOR_PICKUP, 'Preparar', 'boton-preparar-pedido', actionByState);
                setActionsButtons([prepareAction]);
                break;
            case DISPATCHED:
                setFacturar(false);
                setImprimir(false);

                if (order.assigned_channel_id !== null) {
                    isPickup
                        ? setTextStatus(
                            'El pedido quedó en un estado intermedio. Contacte al administrador',
                        )
                        : setTextStatus('Despachado');
                } else {
                    isPickup ? setTextStatus('En tránsito') : setTextStatus('Despachado');
                }

                setActionsButtons([
                    getActionButton(
                        isPickup ? READY_FOR_PICKUP : '',
                        isPickup && 'Recibir',
                        'boton-recibir',
                        isPickup && actionByState,
                    ),
                ]);
                break;
            case PREPARED:
                setFacturar(false);
                setImprimir(true);

                setActionsButtons([
                    getActionButton(
                        DISPATCHED,
                        'Despachar',
                        'boton-preparar',
                        actionByState,
                    ),
                ]);
                break;
            case READY_FOR_PICKUP:
                setFacturar(false);
                setImprimir(true);
                setTextStatus('Listo para retiro');

                setActionsButtons([
                    getActionButton(
                        DELIVERED,
                        'Entregar',
                        'boton-preparar',
                        actionByState,
                    )
                ]);
                break;
            case DELIVERED:
                setFacturar(false);
                setImprimir(false);
                setTextStatus('Entregado');
                break;
            case EXTERNAL_RMA_REQUEST:
                let externalRequestedBy = getRmaRequestLabelStatus(order);
                setTextStatus(externalRequestedBy);
                setCardClass(`${cardClass}card-moto-devolucion`);

                const actions = [];
                const returnAction = getActionButton('', order.assigned_channel_id ? 'Cancelar' : 'Devolver', 'boton-devolver', doReturn);
                actions.push(returnAction);
                if (order.rma_requested_by_user_type === SYSTEM) {
                    const deliverAction = getActionButton(DELIVERED, 'Entregar', 'boton-preparar', actionByState);
                    actions.push(deliverAction);
                }
                setActionsButtons(actions);
                break;
            case REFUNDED:
                setFacturar(false);
                let refundedBy = getCancelLabelStatus(order);
                if (order.assigned_channel_id !== null) {
                    setTextStatus(`Cancelado ${refundedBy}`);
                } else {
                    setTextStatus(`Devuelto ${refundedBy}`);
                }
                break;
            case RMA_REQUESTED:
                let requestedBy = getCancelLabelStatus(order);
                setTextStatus(`RMA iniciado ${requestedBy}`);
                setFacturar(false);
                break;
        }
    }, [order]);

    const showLocation = (config) => {
        let showLocation = false;
        config.forEach((element) => {
            if (element.path === 'save_order_location') {
                if (element.value === '1') {
                    showLocation = true;
                }
            }
        });

        return showLocation;
    }

    const actionByState = (actionState) => {
        if (
            props.useOperator === '1' &&
            order.order_status === READY_FOR_PICKUP
        ) {
            let operator = '';
            do {
                operator = prompt('Operador que entregó el pedido:', '');
                if (operator) {
                    operator = operator.replace(/[^a-zA-Z0-9\u00f1\u00d1 ]/gi, '-');
                }
            } while (operator === '');
            if (!operator) {
                return;
            }
        }

        if (showLocation(configData) === true) {
            if (actionState === READY_FOR_PICKUP && !locationOrderSend) {
                showLocationOrderModal();
                return;
            }
        }

        if (window.confirm('Estás seguro de realizar esta acción?')) {
            if (
                facturar
                && !order.usa_factura_glamit
                && order.order_status === APPROVED
                && actionState === READY_FOR_PICKUP
            ) {
                alert('Debe facturar el pedido antes de pasarlo a Listo para retiro');
                return;
            }

            showModal(<Loader></Loader>);
            axios({
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    'Glamit-Env': localStorage.getItem('store_id'),
                },
                url:
                    localStorage.getItem('url') +
                    '/order/state?state=' +
                    actionState +
                    '&order_id=' +
                    order.order_id +
                    '&external_id=' +
                    order.external_id +
                    '&increment_id=' +
                    order.increment_id,
            })
                .then((response) => {
                    if (response.status === 200) {
                        showModal('Se cambió correctamente el estado de la orden');
                        reloadGrid();
                    }
                })
                .catch((e) => {
                    if (e.response.status === 401 || e.response.status === 403) {
                        window.location.href = `${window.location.origin}/login`;
                    }
                    if (axios.isCancel(e)) {
                        return;
                    }
                    showModal(`${e.name}: ${e.response.data.message}`);
                    clearToStartRow();
                });
        }
    }

    const showLocationOrderModal = () => {
        setIsOpenLocationModal(true);
    }

    const unAssignOrder = (confirmed, reason, description) => {
        const confirmation = !confirmed
            ? window.confirm('Estás seguro de desasignar esta orden?')
            : true;

        if (!confirmation) {
            return;
        }

        if (showUnassignmentReasonsModal(reason)) {
            showModal(<Loader></Loader>);
            const {order_id, increment_id, external_id, skus} = order;
            const orderToSend = {
                store_id: localStorage.getItem('store_id'),
                order_id,
                increment_id,
                reason_id: reason,
                description: description,
                external_id,
                skus,
            };

            axios({
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Glamit-Env': localStorage.getItem('store_id'),
                },
                url: `${localStorage.getItem('url')}/orders/unassign`,
                data: JSON.stringify(orderToSend),
            })
                .then(function (response) {
                    if (response.status === 200) {
                        showModal(
                            `El pedido ${increment_id} fue desasignado correctamente.`,
                        );
                        // Se suma un timeout de 2.5 segundos, para dar algo de margen para que impacte el cambio de estado de la orden en GlamitOMS
                        setTimeout(() => window.location.reload(), 2500);
                    }

                    if (response.status === 202) {
                        showModal(response.data);
                    }
                })
                .catch((e) => {
                    if (e.response.status === 401 || e.response.status === 403) {
                        window.location.href = `${window.location.origin}/login`;
                    }

                    if (axios.isCancel(e)) {
                        return;
                    }

                    showModal(`${e.name}: ${e.response.data.message}`);
                })
                .finally(() => clearToStartRow());
        }
    }

    const doReturn = () => {
        let prompt = '';
        let action = '';

        if (order.assigned_channel_id) {
            prompt =
                'Está seguro de cancelar el pedido?\nEl mismo debe ser desarmado y devuelto al stock del local.';
            action = 'cancelado';
        } else {
            prompt =
                'Está seguro de devolver el pedido?\nEste estado indica que el pedido está siendo devuelto.';
            action = 'devuelto';
        }

        if (window.confirm(prompt)) {
            axios({
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Glamit-Env': localStorage.getItem('store_id'),
                },
                url: `${localStorage.getItem('url')}/order/${order.order_id}/refund`,
            })
                .then(function (response) {
                    if (response.status === 200) {
                        clearToStartRow();
                        showModal(
                            `El pedido ${order.increment_id} fue ${action} correctamente.`,
                        );
                    }
                })
                .catch((e) => {
                    if (e.response.status === 401 || e.response.status === 403) {
                        window.location.href = `${window.location.origin}/login`;
                    } else {
                        if (axios.isCancel(e)) return;
                        clearToStartRow();
                        showModal(`${e.name}: ${e.response.data.message}`);
                    }
                });
        }
    }

    const showUnassignmentReasonsModal = (reason) => {
        if (unassignmentReasons.length === 0) {
            return true;
        }

        if (reason > 0) {
            setUnassignmentReasonId(reason);
            return true;
        }

        setIsOpenUnassignmentReasons(true);
        return false;
    }

    const ucWords = (str) => {
        if (str == null || str === '') {
            return '';
        }

        return str.toLowerCase().replace(/(?<= )[^\s]|^./g, (a) => a.toUpperCase());
    }

    const showCustomModal = () => {
        setIsCustomModalOpen(true);
    }

    const showCommentModal = () => {
        setIsOpenCommentModal(true);
    }

    const showStatusModal = () => setIsOpenStatusModal(true);

    const getChannelName = (channel_id, hub_channel_id) => {
        if (
            hub_channel_id === 'null' ||
            hub_channel_id === null ||
            (props.role !== 'ADMINMARCA' && props.role !== 'ADMIN')
        ) {
            return;
        }

        const channel = channels.filter((e) => +e.channel_id === +hub_channel_id);
        if (channel.length > 0) {
            return `Local: ${channel[0].channel_name}`;
        }
    }

    const getOrderNumber = (store_uses_external_id) => {
        if (store_uses_external_id) {
            return order.external_id;
        }

        return order.increment_id;
    }

    const printLabel = (order) => {
        axios({
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id'),
            },
            url: order.label_url,
        })
            .then(function (response) {
                if (!response.data) {
                    alert(
                        'Estamos generando la etiqueta. Por favor reintentá de nuevo en 5 minutos',
                    );
                } else {
                    window.open(order.label_url, '_blank');
                }
            })
            .catch((e) => console.error(e));
    }

    return (
        <Context.Provider
            value={{
                unAssignOrder,
                setIsCustomModalOpen,
                setIsOpenCommentModal,
                setIsOpenStatusModal,
                setIsOpenUnassignmentReasons,
                setUnassignmentReasonId,
                setUnassignmentReasonDescription,
                configData,
                setIsOpenLocationModal,
                setLocationOrderSend,
            }}
        >
            <div className='grid-container' ref={ref}>
                <div className='display-table'>
                    <div className='check-container'>
                        <label className='container-check'>
                            &nbsp;
                            <CheckBox key={props.index} isChecked={isChecked} {...order} />
                            <span className='checkmark'></span>
                        </label>
                    </div>
                </div>

                <div className={cardClass}>
                    <div className='display-table'>
                        <div className='check-container'>
                            <div className='retira'>
                                {getChannelName(
                                    order.assigned_channel_id,
                                    order.hub_channel_id,
                                )}
                            </div>

                            <div className='pedido'>
                                Pedido{' '}
                                <a
                                    href={`${getOmsUrl()}/oms/order/single?order_id=${
                                        order.order_id
                                    }`}
                                    target='_blank'
                                >
                                    #{getOrderNumber(order.store_uses_external_id)}
                                </a>
                                <FontAwesomeIcon
                                    icon={isOrderNumberCopied ? faClipboard : faCopy}
                                    style={{
                                        width: 16,
                                        height: 16,
                                        marginLeft: 5,
                                        fontSize: 16,
                                        color: isOrderNumberCopied ? '#1890ff' : '#5a5a5a',
                                    }}
                                    title={
                                        isOrderNumberCopied
                                            ? 'Copiado al portapapeles'
                                            : 'Copiar número de pedido al portapapeles'
                                    }
                                    onClick={() => {
                                        navigator.clipboard
                                            .writeText(getOrderNumber(order.store_uses_external_id))
                                            .then(() => {
                                                setIsOrderNumberCopied(true);
                                                setTimeout(() => setIsOrderNumberCopied(false), 3000);
                                            })
                                            .catch((err) =>
                                                console.error(
                                                    `No se pudo copiar el número de pedido en el portapapeles. Error: ${err}`,
                                                ),
                                            );
                                    }}
                                />
                                {isOpenCommentModal === true && <CommentModal order={order}/>}
                                <svg
                                    onClick={showCommentModal}
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='16'
                                    fill='currentColor'
                                    className='bi bi-chat'
                                    viewBox='0 0 16 16'
                                >
                                    <path
                                        d='M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z'/>
                                </svg>
                            </div>

                            {isOpenStatusModal === true && <StatusModal order={order}/>}

                            <div className='creado' onClick={showStatusModal}>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='16'
                                    fill='currentColor'
                                    className='bi bi-card-checklist'
                                    viewBox='0 0 16 16'
                                >
                                    <path
                                        d='M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z'/>
                                    <path
                                        d='M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z'/>
                                </svg>
                                Creado: {moment(order.created_at).format('DD/MM/YYYY, HH:mm:ss')}
                            </div>

                            {isCustomModalOpen === true && <CustomModal order={order}/>}

                            <div className='retira'>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    Retira:{' '}
                                    {order.recipient_full_name !== null
                                        ? ucWords(order.recipient_full_name)
                                        : `${ucWords(order.customer_name)} ${ucWords(
                                            order.customer_lastname,
                                        )}`}
                                    <span className='badge badge-pill badge-info ml-1'>
                    {order.customer_document !== null &&
                    order.customer_document !== 'null'
                        ? order.customer_document.match(numberPattern).join('')
                            .length > 8
                            ? 'Factura A'
                            : ''
                        : ''}
                  </span>
                                    <svg
                                        onClick={showCustomModal}
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='1.5em'
                                        height='1.5em'
                                        fill='currentColor'
                                        className='bi bi-person-fill  ml-1'
                                        viewBox='0 0 16 16'
                                    >
                                        <path
                                            d='M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z'/>
                                    </svg>
                                    <OrderPrintingOptions order={order}/>
                                </div>

                                <div>
                                    <FontAwesomeIcon
                                        style={{width: 16, height: 16, cursor: 'unset'}}
                                        icon={faShoppingBag}
                                        title='Cantidad de bolsas'
                                    />{' '}
                                    x{order.bags_quantity}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='display-table'>
                        <div className='check-container'>
                            <span className='precio'>${order.grand_total}</span>
                        </div>
                    </div>

                    <div className='display-table'>
                        <div className='check-container'>
                            {skus.map((sku, index) => (
                                <SkuContainer key={index} sku={sku}/>
                            ))}
                        </div>
                    </div>

                    <div className='display-table'>
                        <div className='check-container'>
                            <img
                                alt='alt'
                                src={
                                    order.shipping_method === 'pickuppoint_00001' ||
                                    order.shipping_method === 'brandpu_brandpu' ||
                                    order.shipping_method.toLowerCase().endsWith('n_t')
                                        ? '/sucursal.png'
                                        : '/truck.png'
                                }
                                width={
                                    order.shipping_method === 'pickuppoint_00001' ||
                                    order.shipping_method === 'brandpu_brandpu' ||
                                    order.shipping_method.toLowerCase().endsWith('n_t')
                                        ? ''
                                        : '40px'
                                }
                                title={
                                    order.shipping_method === 'pickuppoint_00001' ||
                                    order.shipping_method === 'brandpu_brandpu' ||
                                    order.shipping_method.toLowerCase().endsWith('n_t')
                                        ? 'Pedido de pickup'
                                        : 'Pedido para envío'
                                }
                            />
                        </div>
                    </div>

                    {/* Actions */}
                    <div className='display-table'>
                        <div className='check-container'>
                            <div style={{display: 'flex', flexDirection: 'column', gap: 5}}>
                                {actionsButtons}
                            </div>
                            {isOpenLocationModal === true && (
                                <LocationOrderModal order={order}/>
                            )}
                        </div>
                    </div>

                    <div className='display-table'>
                        <div className='check-container'>
                            {order.assigned_channel_id !== null &&
                            order.order_status === ASSIGNED ? (
                                <button className='boton-desasignar' onClick={unAssignOrder}>
                                    Desasignar
                                </button>
                            ) : (
                                <div className='order-status' style={{fontSize: 12}}>
                                    <OrderStatus label={textStatus}/>
                                </div>
                            )}
                            {isOpenUnassignmentReasons === true && (
                                <UnassignmentReasonsModal
                                    order={order}
                                    unassignmentReasons={unassignmentReasons}
                                />
                            )}
                        </div>
                    </div>

                    <div className='display-table'>
                        <div className='check-container'>
                            {imprimir && (
                                <svg
                                    onClick={() => {
                                        printLabel(order);
                                    }}
                                    width='1.5em'
                                    height='1.5em'
                                    viewBox='0 0 16 16'
                                    className='bi bi-printer'
                                    fill='currentColor'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        d='M11 2H5a1 1 0 0 0-1 1v2H3V3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h-1V3a1 1 0 0 0-1-1zm3 4H2a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h1v1H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z'/>
                                    <path
                                        fillRule='evenodd'
                                        d='M11 9H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM5 8a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H5z'
                                    />
                                    <path d='M3 7.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z'/>
                                </svg>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Context.Provider>
    );
});

export default OrderTableRow;