import React, { useState, useContext, useEffect } from 'react';
import Context from '../../../context';

function useInputDateFrom(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        setDefaultValue: () => setValue(defaultValue),
    }
}

function useInputDateTo(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        setDefaultValue: () => setValue(defaultValue),
    }
}

const Sidebar = (props) => {
    const { closeNav, setDefdateFrom, setDefdateTo, clearToStart } = useContext(Context)
    const [searchDateState, setSearchDateState] = useState(false)
    const [searchDateActive, setSearchDateActive] = useState("setLast7")
    const [searchDateFilter, setSearchDateFilter] = useState([
        { name: "Últimos 7 días", code: "setLast7" },
        { name: "Últimos 30 días", code: "setLast30" },
        { name: "Último año", code: "setLast365" },
        { name: "Personalizada", code: "pers" }
    ])
    const DateFrom = useInputDateFrom(props.dateFrom)
    const DateTo = useInputDateTo(props.dateTo)

    useEffect(() => {
        if (searchDateState) {
            window.document.getElementById("searchDate").style.maxHeight = "1800px";
            window.document.getElementById("searchDateChevron").classList.add("filter-selected");

        } else {
            window.document.getElementById("searchDate").style.maxHeight = "0";
            window.document.getElementById("searchDatePers").style.maxHeight = "0";
            window.document.getElementById("searchDateChevron").classList.remove("filter-selected");

        }
    }, [searchDateState])

    function searchDate() {
        if (searchDateState) {
            setSearchDateState(false)
        } else {
            setSearchDateState(true)
        }
    }
    function setLast7() {
        var date = new Date();
        date.setDate(date.getDate() - 7);
        var date_to = new Date();
        date_to.setDate(date_to.getDate() + 1);
        setDefdateFrom(date.toISOString().slice(0, 10))
        setDefdateTo(date_to.toISOString().slice(0, 10))
        setSearchDateActive("setLast7")
        clearToStart()
        window.document.getElementById("searchDatePers").style.maxHeight = "0";
    }
    function setLast30() {
        var date = new Date();
        date.setDate(date.getDate() - 30);
        var date_to = new Date();
        date_to.setDate(date_to.getDate() + 1);
        setDefdateFrom(date.toISOString().slice(0, 10))
        setDefdateTo(date_to.toISOString().slice(0, 10))
        setSearchDateActive("setLast30")
        clearToStart()
        window.document.getElementById("searchDatePers").style.maxHeight = "0";
    }
    function setLast365() {
        var date = new Date();
        date.setDate(date.getDate() - 365);
        var date_to = new Date();
        date_to.setDate(date_to.getDate() + 1);
        setDefdateFrom(date.toISOString().slice(0, 10))
        setDefdateTo(date_to.toISOString().slice(0, 10))
        setSearchDateActive("setLast365")
        clearToStart()
        window.document.getElementById("searchDatePers").style.maxHeight = "0";
    }
    function setPers() {
        window.document.getElementById("searchDatePers").style.maxHeight = "1800px";
    }
    function submitHandler(event) {
        event.preventDefault()
        setDefdateFrom(DateFrom.value())
        setDefdateTo(DateTo.value())
        setSearchDateActive("pers")
        clearToStart()
    }

    return (
        <div className="menu-container" id="mySidebar">
            <a className="closebtn" onClick={closeNav}>       <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg></a>
            <div className="menu-scroller" id="scroller">
                <div className="filters-title">
                    Filtros
			</div>
                <div className="active-filters-container">
                    <div className="active-filter-box">
                        <div className="display-table">
                            <div className="active-filter-text">
                                {searchDateFilter.map((filter, index) => {
                                    if (filter.code === searchDateActive) {
                                        if (filter.code === "pers") {
                                            return (filter.name + " " + DateFrom.value() + " - " + DateTo.value())
                                        } else {
                                            return (filter.name)
                                        }
                                    }
                                })}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="clear-filters" >

                </div>
                <div className="filters-container">
                    <div className="filter-container">
                        <div className="filter-title-grid" id="searchDateChevron" onClick={searchDate}>
                            <div className="filter-title-chevron">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                            <div className="filter-title-text">
                                Fechas
						</div>
                        </div>
                        <div className="filter-options-container" id="searchDate">
                            <div className="filter-option" onClick={setLast7}>
                                <div>
                                    <span className={searchDateActive === "setLast7" ? 'filter-option-text filter-option-selected' : 'filter-option-text'}>Últimos 7 días</span>
                                </div>
                            </div>
                            <div className="filter-option" onClick={setLast30}>
                                <div>
                                    <span className={searchDateActive === "setLast30" ? 'filter-option-text filter-option-selected' : 'filter-option-text'}>Últimos 30 días</span>
                                </div>
                            </div>
                            <div className="filter-option" onClick={setLast365}>
                                <div>
                                    <span className={searchDateActive === "setLast365" ? 'filter-option-text filter-option-selected' : 'filter-option-text'}>Último año</span>
                                </div>
                            </div>
                            <div className="filter-option" onClick={setPers}>
                                <div>
                                    <span className={searchDateActive === "pers" ? 'filter-option-text filter-option-selected' : 'filter-option-text'}>Personalizada</span>
                                </div>
                                <div className="filter-options-container" id="searchDatePers">
                                    <form onSubmit={submitHandler}>
                                        <div className="filter-option" style={{ marginLeft: "0" }}>
                                            <div>
                                                <span className="filter-option-text-date filter-option-selected">Desde</span>
                                                <input type="text" className="date-filter-input" placeholder={DateFrom} {...DateFrom.bind} />
                                                <span className="date-fiter-go"><svg onClick={submitHandler} width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-right-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                                </svg></span>
                                            </div>
                                        </div>
                                        <div className="filter-option" style={{ marginLeft: "0" }}>
                                            <div>
                                                <span className="filter-option-text-date filter-option-selected">Hasta</span>
                                                <input type="text" className="date-filter-input" placeholder={DateTo} {...DateTo.bind} />
                                                <span className="date-fiter-go"><svg onClick={submitHandler} width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-right-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                                </svg></span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
